import React from 'react';
// import Link from '/src/components/LocalizedLink';
import Layout from '../../components/Layout';
// import IconWhatsApp from '../../assets/images/svg/whatsapp.inline.svg';

import '../../assets/styles/pages/terms-and-privacy-page.scss';

const TermsAndConditions = () => {
  return (
    <Layout
      title={'Terms & Conditions | Iberia Language Academy'}
      description={
        'Here are the detailed terms and conditions between Iberia Language Academy and its students - including payment terms and cancellation.'
      }
    >
      <div className="terms-and-privacy-page">
        <div className="container container--wide-padding">
          <div className="wrapper">
            <h1 className="c-title-42">Terms and conditions</h1>

            <h2 className="subsection__title">Definitions</h2>
            <p className="subsection__par">
              Iberia Language Academy shall be referred to as ‘the school’ from this point onwards.
              'The student' is the person in whose name the booking is made.
            </p>
            <h2 className="subsection__title">This Agreement</h2>
            <p className="subsection__par">
              This agreement is legally binding once the school has received full or partial payment
              for a course and/or accommodation from a student. The laws of Spain shall apply to
              this agreement.
            </p>

            <h2 className="subsection__title">Course Payment</h2>

            <ol className="subsection">
              {/* <h2 className="subsection__title subsection__title--sm">
              The school does not charge an enrollment fee.
              </h2> */}
              <li className="subsection__par"> The school does not charge an enrollment fee.</li>
              <li className="subsection__par">
                Course books are paid for separately from course fees.
              </li>
              <li className="subsection__par">
                A non-refundable deposit of 350€ is required for bookings made more than 4 weeks
                before the course start date.{' '}
              </li>
              <li className="subsection__par">
                The balance of all course fees must be paid 4 weeks before the start date of the
                course. The school reserves the right to cancel the student’s reservation for
                non-payment.
              </li>
              <li className="subsection__par">
                Students who require the school to issue official documentation in order to support
                a student visa application must pay their total course fees in advance. Documents
                can be sent via email or courier to meet any specific requirement. Any related fees
                must be paid by the student in advance to send these documents.
              </li>
              {/* <li className="subsection__par">
                If you are only doing the CertTESOL and NOT applying for a student visa and you
                cancel or postpone your course:
                <ol>
                  <li>
                    More than 4 weeks notice - you will receive no penalty and your deposit and
                    additional paid course fees will be valid for a future course within 12 months,
                    if you wish to cancel you will be entitled to a full refund minus your deposit.{' '}
                  </li>
                  <li>
                    Less than 4 weeks notice - your deposit and additional paid course fees will be
                    valid for a future course within 12 months. If you wish to cancel you will lose
                    the deposit plus 50% of the course fees you have paid. The cut off point is 5pm
                    Spanish time, on the Thursday preceding the Monday course start date.{' '}
                  </li>
                  <li>Once the course has started, no refunds or postponements will be offered.</li>
                  <li>
                    In the case of refunds, any fees owed will be returned to you within 90 days of
                    the refund request and confirmation.
                  </li>
                </ol>
              </li> */}
              <li className="subsection__par">
                The student is responsible for ensuring that payment is received in full by the
                school, without bank charges having to be paid by the school.
              </li>
              <li className="subsection__par">
                The fees for the DELE exam preparation course do not include the DELE exam
                registration fee.
              </li>
              <li className="subsection__par">
                Refunds will be made using the same method of payment the student used to book their
                course.
              </li>
            </ol>

            <h2 className="subsection__title">Class Dates and Holidays</h2>
            <ol className="subsection">
              <li className="subsection__par">
                The school is closed on local and national public holidays and these days will not
                be recovered.
              </li>
              <li className="subsection__par">
                Public holidays in Barcelona are: Easter Friday, Easter Monday, 1st May, 5th June,
                24th June, 15th August, 11th September, 12th October, 1st November, 6th December,
                8th December.
              </li>
              <li className="subsection__par">
                An allowance for 1 public holiday per month of the course is factored into the total
                price of the students’ booking.
              </li>
              <li className="subsection__par">
                Classes start in the 2nd week in January and finish the week before Christmas. Our
                coordination team will give you specific dates when you sign up.
              </li>
            </ol>

            <h2 className="subsection__title">Course Amendments</h2>
            <ol className="subsection">
              <li className="subsection__par">
                Only students in long-term courses can take breaks during their course as part of
                their package.
              </li>
              <li className="subsection__par">
                Long-term courses are courses that are 9 months or longer.
              </li>
              <li className="subsection__par">
                If the student takes a break, it is necessary to take a minimum of 1 full week from
                Monday to Friday. The school cannot guarantee availability in the same group upon
                return.
              </li>
              <li className="subsection__par">
                Holidays need to be communicated via email to the coordination team 7 days in
                advance. Otherwise, the time will not be considered as part of the break entitlement
                but deduced from the course credit.
              </li>
              <li className="subsection__par">
                Students are not permitted to defer their course once it has started. All course
                fees are non-refundable during periods of deferment. The dates of the booking cannot
                be modified once the course has started.
              </li>
              <li className="subsection__par">
                All students who wish to make changes to their course must notify the school in
                writing via email at least 10 days before the course starts.
              </li>
              <li className="subsection__par">
                A course reservation is assigned on an individual basis and therefore it cannot be
                re-assigned or transferred to another student.
              </li>
              <li className="subsection__par">
                Students who have been issued with the school’s official documents for the purpose
                of applying for a visa cannot change the end date of their course once it has
                started.
              </li>
              <li className="subsection__par">
                Group classes cannot be converted into private lessons.
              </li>
              <li className="subsection__par">
                In the case of school closure due to force majeure, in-person classes will be
                replaced by virtual classes maintaining the same content and following the same
                academic program. No refunds will be processed.
              </li>
            </ol>

            <h2 className="subsection__title">Course Cancellations</h2>
            <ol className="subsection">
              <li className="subsection__par">
                All students who wish to cancel their course must notify the school in writing via
                email. Notifications not received via this method will not be accepted.
              </li>
              <li className="subsection__par">
                Students who cancel their course 30 days or more before the course start date will
                lose their non-refundable deposit (350€).
              </li>
              <li className="subsection__par">
                Students who cancel their course under 30 days before the course start date will
                lose their deposit and be charged 50% of the total course price.
              </li>
              <li className="subsection__par">
                Once a course has started no refunds will be given.
              </li>
              <li className="subsection__par">
                Students who have been issued with the school’s official documents for the purpose
                of applying for a student visa type D cannot cancel their course unless a copy of
                the official visa rejection document is provided to the school via email. Tourist
                visa type C rejection will not be accepted.
              </li>
              <li className="subsection__par">
                In case of providing an appropriate visa rejection letter, the student will receive
                a refund, minus the non-refundable 350€ deposit.
              </li>
              <li className="subsection__par">
                If the student receives their student visa successfully but has to cancel for
                personal reasons, they will need to cancel their visa by contacting the Spanish
                embassy or consulate that issued the visa. After the visa is cancelled, they will
                need to send the cancellation notice to the school via email in order to receive a
                refund, minus the 350€ non-refundable deposit.
              </li>
              <li className="subsection__par">
                Students who do not provide an official visa rejection document will be charged the
                full course price.
              </li>
              <li className="subsection__par">
                Where a refund is given, any bank charges related to transfers and exchange rate
                movements will be deducted from the refundable amount due.
              </li>
            </ol>

            <h2 className="subsection__title">Accommodation</h2>
            <ol className="subsection">
              <li className="subsection__par">
                The minimum booking period for accommodation is 4 weeks.
              </li>
              <li className="subsection__par">
                Students are required to notify the school of accommodation needs at least 4 weeks
                prior to arrival.
              </li>
              <li className="subsection__par">
                If accommodation is requested less than 4 weeks before the course start date, the
                school cannot guarantee accommodation.
              </li>
              <li className="subsection__par">
                Accommodation fees must be paid before the confirmation of accommodation at least 4
                weeks prior to course start date or arrival. The school will not make any
                accommodation arrangements until full payment has been received.
              </li>
              <li className="subsection__par">
                The school can not make any guarantees on location, flat type or included amenities.
              </li>
              <li className="subsection__par">
                In Barcelona and Europe in general, the buildings are older and smaller than you may
                be accustomed to in your home country. We provide accommodation in line with the
                standards expected within Spain. The school can’t change accommodation based on
                personal tastes and preferences.
              </li>
              <li className="subsection__par">
                Trainees will be expected to pay a refundable €200 security deposit directly to the
                accommodation host when they arrive.This will be refunded to you when you leave,
                assuming there are no damages to the flat.
              </li>
              <li className="subsection__par">
                If there are damages to the flat, this is considered a dispute between the student
                and the host.
              </li>
              <li className="subsection__par">
                The 200€ security deposit can either be paid in cash or electronically, at the
                discretion of the host.
              </li>
              <li className="subsection__par">
                If a student cancels their accommodation for more than 4 weeks of arrival, the
                student will be entitled to a full refund of accommodation fees.
              </li>
              <li className="subsection__par">
                If a student cancels their accommodation within 4 weeks of arrival the student will
                forfeit 50% of their accommodation fees in order to cover host fees.
              </li>
              <li className="subsection__par">
                If a student cancels their accommodation within 2 weeks of arrival the student will
                forfeit all of their accommodation fees in order to cover host fees.
              </li>
              <li className="subsection__par">
                If you arrive late due to sickness, visa delay, accidents, etc, you will not be
                entitled to any unused accommodation days. For example, if you arrive 7 days late,
                those days cannot be recovered and you will not be entitled to a refund for those
                days.
              </li>
              <li className="subsection__par">
                If a student arrives after 10pm, a late arrival fee may be charged by the host.
              </li>
              <li className="subsection__par">
                The host cannot be held responsible for loss or theft of personal belongings. We
                recommend students take out the appropriate travel insurance for their personal
                items.{' '}
              </li>
              <li className="subsection__par">
                Once the student has arrived and for any reason decides not to stay in the assigned
                flat, they will not be entitled to a refund. The security deposit will be refunded
                as long as there are no damages.{' '}
              </li>
              <li className="subsection__par">
                The school has the right to remove a student from the accommodation as a result of
                unacceptable behaviour that impacts the host or other guests. The student will not
                receive any reimbursement of fees if they are removed from the accommodation, and
                the school is not obliged to find alternative accommodation.
              </li>
            </ol>

            <h2 className="subsection__title">Certificates</h2>
            <ol className="subsection">
              <li className="subsection__par">
                The school offers an attendance certificate, stating the dates of the course, the
                total number of study hours and the final level the student will reach. This
                certificate is not equivalent to an official DELE Certificate.
              </li>
              <li className="subsection__par">
                We charge a 10€ fee for issuing attendance certificates. We charge this as we do not
                charge enrolment fees.
              </li>
              <li className="subsection__par">
                In order to receive the school certificate, the attendance of the student has to be
                at least 80%.
              </li>
              <li className="subsection__par">The certificates are only prepared upon request.</li>
              <li className="subsection__par">
                Students must request and pay for the certificate at the beginning of their final
                course week at the latest. The certificates can be picked up from the school
                reception on the last day of the course.
              </li>
              <li className="subsection__par">
                All requests received in the last days of the course will be processed in the
                following week.
              </li>
              <li className="subsection__par">
                If the student requests a certificate by post, an additional 15€ fee will be
                charged.
              </li>
            </ol>

            <h2 className="subsection__title">General</h2>
            <ol className="subsection">
              <li className="subsection__par">
                The school expects good conduct of behaviour within its premises. It is expected
                that students respect the academy, its staff and other students.
              </li>
              <li className="subsection__par">
                The school has the right to remove a student from the course and academy as a result
                of unacceptable behaviour that impacts staff and students. The student will not
                receive any reimbursement of course fees if they are removed from the course..
              </li>
              <li className="subsection__par">
                Students are required to make a confidential declaration about any medical
                conditions (physical or mental) that may affect their performance on the course.
                This information is necessary to allow students to make the necessary adjustments to
                ensure that students have a positive experience.
              </li>
              <li className="subsection__par">
                The school cannot be held responsible for loss or theft of personal belongings.
              </li>
              <li className="subsection__par">
                The school reserves the right to update and make changes to their Terms and
                Conditions as it sees fit.
              </li>
            </ol>
            <p className="subsection__par">
              Upon signing the student enrolment form or paying the course fees, the student accepts
              all the terms and conditions stated above.
            </p>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default TermsAndConditions;
